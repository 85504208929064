import React from "react";

const TaskSection = ({ data, header }) => {
  if(!data) return <></>
  return (
    <div>
      <h4 className="fs-20 mb-3">{header}</h4>
      <div className="ms-2">
        {data.map((task, index) => (
          <p key={index}>
            {index + 1}. {task}
          </p>
        ))}
      </div>
    </div>
  );
};

export default TaskSection;
