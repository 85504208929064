import { useState, useCallback, useEffect } from "react";
import { quests } from "../services/api/models";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";

// to test quest questions
const tempQuestQuestions = [
  // {
  //   id: 3,
  //   label: "Do you know how to deal with Feathers backend?",
  //   name: "q1",
  //   required: true,
  //   type: "text",
  // },
  // {
  //   id: 2,
  //   label: "Did you use React before?",
  //   name: "q2",
  //   required: true,
  //   type: "boolean",
  // },
  // {
  //   id: 3,
  //   label: "Did you use Auth0 before?",
  //   name: "q3",
  //   required: true,
  //   type: "boolean",
  // },
];

/**
 * Custom hook for managing quest registration.
 *
 * This hook provides functionality for registering a contestant for a quest, including handling API requests,
 * managing loading states, and tracking errors.
 *
 * @param {string} questId - The ID of the quest for which the registration is to be processed.
 *
 * @returns {{
 *   isLoading: boolean,              // A boolean indicating if the registration process is ongoing.
 *   data: object | null,             // The data returned from the API upon successful registration, or null if no data is available.
 *   error: object | null,            // An error object if an error occurred during the registration process, or null if no error is present.
 *   questRegistrationRequest: function // A function that initiates the quest registration process. It accepts registration data and returns a promise.
 * }}
 *
 *
 **/
const useQuestRegisterAPI = (questId) => {
  const { auth } = useSelector((state) => state.auth);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [questQuestions, setQuestQuestions] = useState(null);

  useEffect(() => {
    try {
      // make request here to get quest questions
      setQuestQuestions(tempQuestQuestions);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const questRegistrationRequest = useCallback(
    async (registrationData) => {
      setIsLoading(true);
      setError(null);

      try {
        // Check for required parameters
        if (!questId || !registrationData) {
          throw new Error("Quest registration data is missing");
        }

        // clients can't register
        if (auth.user.role === "client") {
          throw new Error("Clients can't register for quests");
        }

        // Prepare authorization parameters
        const accessToken = await getAccessTokenSilently();
        const options = { headers: { Authorization: `Bearer ${accessToken}` } };

        // Prepare registration data
        // ! Commented properties are marked as additional by the backend
        const requestData = {
          questId: Number.parseInt(questId),
          // isInvited: false,
          // isApproved: !registrationData.requiresApproval,
          // status: registrationData.requiresApproval ? "pending" : "approved",
          role: auth.user.role === "contestant" ? "contestant" : "reviewer",
          // joinedOn: new Date(),
        };

        // Register user for the quest
        const response = await quests.questsQuestIdEnlistPost(
          questId,
          requestData,
          options
        );

        if (!response) {
          throw new Error("Registration API didn't have a response");
        }

        setData(response);
      } catch (error) {
        setError(error);
        console.error("Quest registration request error:", error);

        if (error?.response?.data?.data?.userId?.message) {
          throw new Error(error.response.data.data.userId.message);
        } else {
          throw new Error(error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [getAccessTokenSilently, questId]
  );

  return { isLoading, data, error, questRegistrationRequest, questQuestions };
};

export default useQuestRegisterAPI;
