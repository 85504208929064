import { useEffect, useState } from 'react';
import { quests } from '../services/api/models.ts'; // Adjust the import path if needed
import { useAuth0 } from '@auth0/auth0-react';

const useRegistrationsAPI = (questId) => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  
  const { getAccessTokenSilently, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    async function getData() {
      try {
        if (!isAuthenticated) {
          await loginWithRedirect();
        }

        const accessToken = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${accessToken}` };
        const options = { headers };
        const limit = 1000, skip = 0, filter = {};
        const response = await quests.questsQuestIdEnlistGet(questId, limit, skip, filter, options);
        console.log("get registrations :::::::::::::", response.data.data);
        setData(response.data.data || []);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, [questId]);

  return { isLoading, data, error };
};

export default useRegistrationsAPI;
