import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import useQuestsAPI from "../../../hooks/useQuestsAPI";
import { Link, useNavigate, useParams } from "react-router-dom";
import useQuestRegisterAPI from "../../../hooks/useQuestRegisterAPI.js";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  FormGroup,
  RadioGroup,
  Radio,
  FormHelperText,
} from "@mui/material";
import {
  TextFieldCustomization,
  FillCustomization,
  LabelCustomization,
  RadioGroupCustomization,
} from "./materialUI customization.js";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

/**
 * QuestRegistration Component
 * Component to show inputs required for registration for a form while also handling the required API requests.
 *
 * @returns {JSX.Element} The rendered component.
 */
const QuestRegistration = () => {
  const navigate = useNavigate();
  const {user} = useAuth0();
  const isClient = user.role === "client";
  // Initialize react-hook-form
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Get quest data
  const { id } = useParams();
  const { isLoading, data , error} = useQuestsAPI(id);
  // Handle if enter invalid id in url
  const res = (error) ? error.response: null;
  useEffect(() => {
    if (res && res.status === 404) {
      navigate("/page-error-404");
    }
  }, [res, navigate]);

  // Flag for end of phase
  const phaseEnded =
    data?.phase !== "registration" || data?.registrationDeadline < new Date();
 

  // Quest registration custom hook
  const {
    questRegistrationRequest,
    questQuestions,
    isLoading: isRegistrationLoading,
  } = useQuestRegisterAPI(id);

  // Register user for quest functionality
  const onSubmit = async (formData) => {
    try {
      await questRegistrationRequest({ ...formData, ...data });

      // Notify user and navigate
      toast.success("Registered");
      setTimeout(() => {
        navigate("/quest-list");
      }, 3500);
    } catch (error) {
      console.log(error.message);
      toast.error(
        error.message.charAt(0).toUpperCase() + error.message.slice(1)
      );
    }
  };

  return (
    <>
        <div className="d-flex align-items-center mb-4 flex-wrap justify-content-between">
        <h3>Quest Registration</h3>
        <Link to={`/quest-view/${id}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-right ms-2" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
          </svg>
        </Link>
      </div>

      <div className="quest-view">
        <div className="card w-100">
          {/* WAIT FOR RESPONSE */}
          {isLoading ? (
            <div className="card-header d-flex align-items-center justify-content-start gap-5 flex-wrap">
              <h3 className="mb-4 me-auto">Loading...</h3>
            </div>
          ) : (
            <>
              <div className="card-header d-flex align-items-center justify-content-start gap-5 flex-wrap">
                <h3>{`${id} - ${data.title} Quest`}</h3>
                {phaseEnded && (
                  <h3 className="color-primary">Registration Phase Ended!</h3>
                )}
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* QUEST QUESTIONS */}
                {questQuestions && questQuestions.length > 0 && (
                  <div className="card-body pb-0">
                    <h3>Quest Related Questions</h3>
                    <div className="places mb-5">
                      <div className="d-flex gap-4 flex-column mb-1 text-inputs-width">
                        {questQuestions.map((question) => (
                          <Controller
                            key={question.id}
                            name={question.name}
                            control={control}
                            defaultValue={
                              question.type == "boolean" ? "true" : ""
                            }
                            rules={{ required: question.required }}
                            render={({ field }) =>
                              question.type == "boolean" ? (
                                <div>
                                  <p className="mb-0 fs-5">{question.label}</p>
                                  <RadioGroup
                                    {...field}
                                    sx={RadioGroupCustomization}
                                  >
                                    <FormControlLabel
                                      value="true"
                                      control={<Radio sx={FillCustomization} />}
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      value="false"
                                      control={<Radio sx={FillCustomization} />}
                                      label="No"
                                    />
                                  </RadioGroup>
                                </div>
                              ) : (
                                <TextField
                                  {...field}
                                  label={question.label}
                                  variant="standard"
                                  sx={TextFieldCustomization}
                                  fullWidth
                                  error={!!errors[question.name]}
                                  helperText={
                                    errors[question.name]
                                      ? "This field is required"
                                      : ""
                                  }
                                />
                              )
                            }
                          />
                        ))}
                      </div>
                    </div>
                    <hr />
                  </div>
                )}

                {/* TERMS & CONDITIONS */}
                <div className="card-body">
                  <h3>Terms and Conditions</h3>
                  <p className="user-agreement">
                    By Registering to a quest, you agree to the{" "}
                    <a
                      href="https://code-quests.com/privacy-policy-2/"
                      className="link"
                    >
                      terms of service of code-quests.com
                    </a>{" "}
                    If you have any questions please reach to{" "}
                    <a href="support@code-quests.com" className="link">
                      support@code-quests.com
                    </a>
                  </p>

                  <FormGroup>
                    <Controller
                      name="terms1"
                      control={control}
                      defaultValue={false}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox {...field} sx={FillCustomization} />
                            }
                            sx={LabelCustomization}
                            label="Quests payment is applicable for Egyptian nationals only."
                          />
                          {errors.terms1 && (
                            <FormHelperText error>
                              This field is required.
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                    <Controller
                      name="terms2"
                      control={control}
                      defaultValue={false}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox {...field} sx={FillCustomization} />
                            }
                            sx={LabelCustomization}
                            label={
                              <Typography component="span" variant="body1">
                                By submitting your work (code, designs, etc.),
                                you agree to transfer copyrights and ownership
                                to
                                <a
                                  href="mailto:support@code-quests.com"
                                  className="link"
                                >
                                  {" "}
                                  code-quests.com{" "}
                                </a>
                                and its clients.
                              </Typography>
                            }
                          />
                          {errors.terms2 && (
                            <FormHelperText error>
                              This field is required.
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                    <Controller
                      name="terms3"
                      control={control}
                      defaultValue={false}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox {...field} sx={FillCustomization} />
                            }
                            sx={LabelCustomization}
                            label="You will not share personal or private information about
                          other contestants like names, emails, phone numbers, or
                          employment status."
                          />
                          {errors.terms3 && (
                            <FormHelperText error>
                              This field is required.
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                    <Controller
                      name="terms4"
                      control={control}
                      defaultValue={false}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox {...field} sx={FillCustomization} />
                            }
                            sx={LabelCustomization}
                            label="You will not share information about the projects,
                          requirements, market analysis, source code, or any other
                          documents shared with you by the project manager or others
                          in the quest."
                          />
                          {errors.terms4 && (
                            <FormHelperText error>
                              This field is required.
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </FormGroup>
                  <hr />
                </div>

                {/* FIRST CONTEST */}
                <div className="card-body pt-0">
                  <h3>Is this your first contest with us?</h3>

                  <Controller
                    name="firstTime"
                    control={control}
                    rules={{ required: true }}
                    defaultValue="yes"
                    render={({ field }) => (
                      <RadioGroup {...field} sx={RadioGroupCustomization}>
                        <FormControlLabel
                          value="yes"
                          control={<Radio sx={FillCustomization} />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio sx={FillCustomization} />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                </div>

                {/* SUBMIT */}
                <div className="card-footer border-0">
                  {!(phaseEnded) && (
                    <button
                      className="btn btn-primary px-5 mb-4 fs-14"
                      type="submit"
                      disabled={isRegistrationLoading || isClient}
                    >
                      REGISTER
                    </button>
                  )}
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default QuestRegistration;
