/* tslint:disable */
/* eslint-disable */
/**
 * Code Quests APIs
 * API docs using swagger
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {AuthenticationApi, CategoriesApi, FeedbackApi, MyApi, OrgsApi, PrizesApi, QuestsApi, UsersApi} from "./api.ts";

import {Configuration } from "./configuration"


let config :Configuration = new Configuration({basePath: process.env.REACT_APP_API_URL})
export const users = new UsersApi(config)
export const auth = new AuthenticationApi(config)
export const categories = new CategoriesApi(config)
export const orgs = new OrgsApi(config)
export const quests = new QuestsApi(config)
export const prizes = new PrizesApi(config)
export const feedbacks = new FeedbackApi(config)
export const myService = new MyApi(config)
