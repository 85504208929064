import { useEffect, useState } from 'react'
import {auth, quests} from '../services/api/models.ts';
import { useAuth0 } from '@auth0/auth0-react';

const useQuestsAPI = (id) => {
  const [isLoading, setLoading] = useState(true);
  const [data , setData] = useState([]);
  const [error , setError] = useState(null);
  const {getAccessTokenSilently, isAuthenticated, loginWithRedirect , user} = useAuth0();
  useEffect(()=>  {
    async function getData (){
    try {

      if(!isAuthenticated){
        await loginWithRedirect()
      }
      
      let accessToken = await getAccessTokenSilently();

      let headers = {Authorization: "Bearer "+ accessToken }
      let Options = {headers:headers} 
      if(id){
          let quest =  await quests.questsIdGet(id,Options)
          return quest.data
      }else{
        let questsList = await quests.questsGet(1000,0,{},Options)
         return questsList.data.data
      }
    } catch (err) {
      setError(err);
    }
  }

  getData().then((res)=>{
    setData(res || [])
  }).finally(()=>{
    setLoading(false);
  })
  
    
   
  },[id]);

  
  return {isLoading , data , error}
}

export default useQuestsAPI
