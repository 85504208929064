export const TextFieldCustomization = {
  "& label.Mui-focused": {
    color: "var(--primary)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "var(--primary)",
  },
};

export const FillCustomization = {
  "&.Mui-checked": {
    color: "var(--primary)",
  },
};

export const LabelCustomization = {
  "& .MuiFormControlLabel-label": {
    fontFamily: "Arial, sans-serif",
    fontSize: "16px",
  },
};

export const RadioGroupCustomization = {
  display: "flex",
  flexDirection: "row",
  gap: 3,
};

export const SliderCustomization = {
  color: "#ff0000",
  "& .MuiSlider-thumb": {
    backgroundColor: "var(--primary)",
  },
  "& .MuiSlider-track": {
    backgroundColor: "var(--primary-hover)",
  },
  "& .MuiSlider-rail": {
    backgroundColor: "#cccccc",
  },
};