import React, { Fragment, useReducer, useState, useEffect , useRef} from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import LightGallery from 'lightgallery/react';
import {users} from '../../../../services/api/models.ts'
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import {ToastContainer , toast} from "react-toastify";

//** Import Image */
//** Import Image */
import profile01 from "../../../../images/profile/1.jpg";
import profile02 from "../../../../images/profile/2.jpg";
import profile03 from "../../../../images/profile/3.jpg";
import profile04 from "../../../../images/profile/4.jpg";
import profile05 from "../../../../images/profile/5.jpg";
import profile06 from "../../../../images/profile/6.jpg";
import profile07 from "../../../../images/profile/7.jpg";
import profile08 from "../../../../images/profile/8.jpg";
import profile09 from "../../../../images/profile/9.jpg";
import profile from "../../../../images/profile/profile.png";
import PageTitle from "../../../layouts/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { handleErrorMessage, roleData, getValidationSchema } from "../../../../validation/profileData.validation.js";
import { loginConfirmedAction } from "../../../../store/actions/AuthActions.js";
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form'; // Assuming you're using react-hook-form
import { yupResolver } from '@hookform/resolvers/yup';

const galleryBlog = [
	{image: profile03}, {image: profile04},
	{image: profile02}, {image: profile04},
	{image: profile03}, {image: profile02},
];
const initialState = false;
const reducer = (state, action) =>{
	switch (action.type){
		case 'sendMessage':
			return { ...state, sendMessage: !state.sendMessage }		
		case 'postModal':
			return { ...state, post: !state.post }
		case 'linkModal':
			return { ...state, link: !state.link }		
		case 'cameraModal':
			return { ...state, camera: !state.camera }		
		case 'replyModal':
			return { ...state, reply: !state.reply }
		default:
			return state	
	}	
}

const AppProfile = () => {
	const {loginWithRedirect , user} = useAuth0();
	const divRef = useRef(null);
	const {auth} = useSelector(state => state.auth);
	console.log(auth)
	const dispatchAction = useDispatch();
	const onInit = () => {
		//console.log('lightGallery has been initialized');
	};
  	const options = {
     	settings: {
			overlayColor: "#000000",
     	},
 	};
	 const validationSchema = getValidationSchema(false);

	 const { register, handleSubmit, setValue, formState: { errors } } = useForm({
		resolver: yupResolver(validationSchema), // Integrate Yup validation
		defaultValues: {
			...auth.user,
			interestFields: auth.user.interestFields ? auth.user.interestFields.split(', ').map(field => field.trim()) : [] // Ensure it's an array
		  }	  
	  });
	
	  // Use effect to set the form values if they are fetched or updated asynchronously
	  useEffect(() => {
		if (auth.user) {
		  for (const key in auth.user) {
			if (key === 'interestFields' && typeof auth.user[key] === 'string') {
				const interestedArray =  auth.user.interestFields.split(', ').map(field => field.trim());
				console.log(interestedArray)
				setValue(key, interestedArray);
			  } else {
			setValue(key, auth.user[key]);
			  }
		  }
		}
	  }, [auth.user, setValue]);
	  console.log(errors)
	
	  const onSubmit =  async(formData) => {
	  console.log('Form data:', formData); // This will log the unvalidated data
	  const newData = roleData(formData);
	  		setClickLoading(true);
		try{
			const requestBody =  {...newData, avatar: avatar || auth.user.picture }
			console.log(requestBody)
			const headers = {Authorization: "Bearer "+ auth.accessToken }
			let Options = {headers:headers}
			const userFilter = {"auth0Id": user.sub};
			
			const response = await users.usersPatch(requestBody, userFilter , Options);
			console.log("edit user data in db : ", response.data);

			if (response.status === 200) {
				console.log("Success update user data.");
				toast.success("Success update profile");
				setTimeout(()=>{
					loginWithRedirect(); // to send new user data and new token
				  },1000)
		   }
		  
		}
		catch(err){
			if(err.response){
				const message = handleErrorMessage(err.response.data.message);
				toast.error(message);
			  }else{
				toast.error(err.message || "Can't Edit Account Now, Try Another Time." );
			  }
			  console.log("Error in updating user data", err);
		}
		setClickLoading(false);
	  }

	const [state, dispatch] = useReducer(reducer, initialState);
	const studyYearList = ['1' , '2' , '3' , '4' , 'Graduate'];
	const jobStateList = ['Part time' , 'Full time' , 'Free hours' , 'Intern' , 'Other'];
	const fieldsInterestList = [ "Cyber Security" , "Game Development", "Game Art", "Game Design", "Devops / Cloud", "Backend", "Frontend", "QA (Testing)", "UI / UX",
								 "Mobile Dev (Cross Platform)", "Android","IOS", "Machine Learning","Model Data","Robotics","IOT","Other"];

	useEffect(() => {
		if(auth.user.interestFields){
			setInterestFields(auth.user.interestFields.split(", "))
		}
	  }, [auth.user.interestFields]);
	const navigate = useNavigate();
	const [studyYear, setStudyYear] = useState(auth.user.studyYear ||"");
	const [interestFields, setInterestFields] = useState([]);
	const [jobState, setJobState] = useState(auth.user.jobState ||"");
	const [avatar, setAvatar] = useState(auth.user.avatar || auth.user.picture || user.picture || "");
	const [clickLoading , setClickLoading] = useState(false)
	const [openInterestFields , setOpenInterestFields] = useState(false);

		   // Handle click outside
		   const handleClickOutside = (event) => {
			if (divRef.current && !divRef.current.contains(event.target)) {
		  setOpenInterestFields(false);
		}
	  };
	  useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
		  document.removeEventListener('mousedown', handleClickOutside);
		};
	  }, []);
	const handleJobState = (e) => {
		const selectedJobState = jobStateList.find((job) => job === e.target.value);
		setJobState(selectedJobState);

	  };
	  const handleStudyYear = (e) => {
		const selectedYear = studyYearList.find((year) => year === e.target.value);
		setStudyYear(selectedYear);
		setValue('studyYear', selectedYear); 
	  };
	  const handleFieldsChange = (event) => {
		const value = event.target.value;
		let newInterestFields;
		if (event.target.checked) {
		  newInterestFields = [...interestFields, value];
		} else {
		  newInterestFields = interestFields.filter((field) => field !== value);
		}
		setInterestFields(newInterestFields);
		console.log(newInterestFields)
		setValue('interestFields', newInterestFields); 
	  };
	// const {user}= JSON.parse(localStorage.getItem('userDetails'));
	function isUrlValid(string) {
		try {
		  new URL(string);
		  return true;
		} catch (err) {
		  return false;
		}
	  }
	
	return (
		<Fragment>
		  <PageTitle activeMenu="Profile" motherMenu="App" />	
		  <div className="row">
			<div className="col-lg-12">
			  <div className="profile card card-body px-3 pt-3 pb-0">
				<div className="profile-head">
				  <div className="photo-content ">
					<div className="cover-photo rounded"></div>
				  </div>
				  <div className="profile-info">
					<div className="profile-photo">
					  <img src={auth.user.avatar || user.picture || profile} className="img-fluid rounded-circle" alt="profile"/>
					</div>
					<div className="profile-details">
					  <div className="profile-name px-3 pt-2">
						<h4 className="text-primary mb-0">{user.username || user.nickname || auth.user.given_name || ""}</h4>
						<p>{auth.user.role || ""}</p>
					  </div>
					  <div className="profile-email px-2 pt-2">
						<h4 className="text-muted mb-0">{auth.user.email || user.email || ""}</h4>
						<p>Email</p>
					  </div>
					  {/* <Dropdown className="dropdown ms-auto">
						<Dropdown.Toggle
						  variant="primary"
						  className="btn btn-primary light sharp i-false"
						  data-toggle="dropdown"
						  aria-expanded={true}
						>
						  <svg
							xmlns="http://www.w3.org/2000/svg"
							//    xmlns:xlink="http://www.w3.org/1999/xlink"
							width="18px"
							height="18px"
							viewBox="0 0 24 24"
							version="1.1"
						  >
							<g
							  stroke="none"
							  strokeWidth="1"
							  fill="none"
							  fillRule="evenodd"
							>
							  <rect x="0" y="0" width="24" height="24"></rect>
							  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
							  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
							  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
							</g>
						  </svg>
						</Dropdown.Toggle>
						<Dropdown.Menu className="dropdown-menu dropdown-menu-right">
						  <Dropdown.Item className="dropdown-item">
							<i className="fa fa-user-circle text-primary me-2" />
							View profile
						  </Dropdown.Item>
						  <Dropdown.Item className="dropdown-item">
							<i className="fa fa-users text-primary me-2" />
							Add to close friends
						  </Dropdown.Item>
						  <Dropdown.Item className="dropdown-item">
							<i className="fa fa-plus text-primary me-2" />
							Add to group
						  </Dropdown.Item>
						  <Dropdown.Item className="dropdown-item">
							<i className="fa fa-ban text-primary me-2" />
							Block
						  </Dropdown.Item>
						</Dropdown.Menu>
					  </Dropdown> */}
					</div>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		  <div className="row">
			{/* <div className="col-xl-4">
				<div className="row">
					<div className="col-lg-12">
						<div className="card">
							<div className="card-body">
								<div className="profile-statistics">
									<div className="text-center">
										<div className="row">
											<div className="col">
												<h3 className="m-b-0">150</h3><span>Follower</span>
											</div>
											<div className="col">
												<h3 className="m-b-0">140</h3> <span>Place Stay</span>
											</div>
											<div className="col">
												<h3 className="m-b-0">45</h3> <span>Reviews</span>
											</div>
										</div>
										<div className="mt-4">
											<Link	to="/post-details"	className="btn btn-primary mb-1 me-1">Follow</Link>
											<Link to={"#"} className="btn btn-primary mb-1 ms-1" onClick={() => dispatch({type:'sendMessage'})}>Send Message</Link>
										</div>
									</div>
								  
								</div>
							</div>
						</div>
					</div>	
					<div className="col-lg-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<h5 className="text-primary">Today Highlights</h5>
							</div>	
							<div className="card-body pt-3"	>	
								<div className="profile-blog ">
									<img  src={profile01}  alt="profile" className="img-fluid  mb-4 w-100 " />
									<Link to="/post-details"> <h4>Darwin Creative Agency Theme</h4> </Link>
									<p className="mb-0">
										A small river named Duden flows by their place and supplies
										it with the necessary regelialia. It is a paradisematic
										country, in which roasted parts of sentences fly into your
										mouth.
									</p>
								</div>
							</div>	
						</div>
					</div>
					<div className="col-lg-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<h5 className="text-primary ">Interest</h5>
							</div>
							<div className="card-body pt-3">
								<div className="profile-interest ">	
									<LightGallery
										onInit={onInit}
										speed={500}
										plugins={[lgThumbnail, lgZoom]}
										elementClassNames="row sp4"
									>
										
										{galleryBlog.map((item,index)=>(
										<div data-src={item.image} className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1" key={index}>
											<img src={item.image} style={{width:"100%"}} alt="gallery"/>
										</div>
										))}
									</LightGallery>										
								</div>
							</div>	
						</div>
					</div>	
					<div className="col-lg-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<h5 className="text-primary">Our Latest News</h5>
							</div>	
							<div className="card-body pt-3">
								<div className="profile-news">
								  <div className="media pt-3 pb-3">
									<img src={profile05} alt="" className="me-3 rounded" width={75}/>
									<div className="media-body">
										<h5 className="m-b-5">
											<Link to="/post-details" className="text-black">
												Collection of textile samples
											</Link>
										</h5>
										<p className="mb-0">I shared this on my fb wall a few months back, and I thought. </p>
									</div>
								  </div>
								  <div className="media pt-3 pb-3">
									<img src={profile06} alt=""  className="me-3 rounded" width={75}/>
									<div className="media-body">
										<h5 className="m-b-5">
											<Link to="/post-details" className="text-black">
											Collection of textile samples
											</Link>
										</h5>
										<p className="mb-0">
											I shared this on my fb wall a few months back, and I
											thought.
										</p>
									</div>
								  </div>
								  <div className="media pt-3 ">
									<img src={profile07} alt="" className="me-3 rounded" width={75} />
									<div className="media-body">
										<h5 className="m-b-5">
											<Link to="/post-details" className="text-black">
												Collection of textile samples
											</Link>
										</h5>
										<p className="mb-0">
											I shared this on my fb wall a few months back, and I thought.
										</p>
									</div>
								  </div>
								</div>
							</div>	
						</div>
					</div>	
				</div>	
			</div>	 */}
			<div className="col-xl-12">
			  <div className="card">
				<div className="card-body">
				  <div className="profile-tab">
					<div className="custom-tab-1">
						<Tab.Container defaultActiveKey='About'>					
							<Nav as='ul' className="nav nav-tabs">
								{/* <Nav.Item as='li' className="nav-item">
									<Nav.Link to="#my-posts" eventKey='Posts'>Posts</Nav.Link>
								</Nav.Item> */}
								<Nav.Item as='li' className="nav-item">
									<Nav.Link to="#about-me"  eventKey='About'>About Me</Nav.Link>
								</Nav.Item>
								{/* <Nav.Item as='li' className="nav-item">
									<Nav.Link to="#profile-settings" eventKey='Setting'>Edit</Nav.Link>
								</Nav.Item> */}
							</Nav>
							<Tab.Content>
								{/* <Tab.Pane id="my-posts"  eventKey='Posts'>
									<div className="my-post-content pt-3">
										<div className="post-input">
												<textarea name="textarea" id="textarea" cols={30} rows={5} className="form-control bg-transparent" placeholder="Please type what you want...."defaultValue={""}/>
												<Link to="/app-profile" className="btn btn-primary light px-3 me-1"  onClick={() => dispatch({type:'linkModal'})}>
													<i className="fa fa-link m-0" />{" "}
												</Link>
											
											<Link to={"#"} className="btn btn-primary light px-3 me-1"  data-target="#cameraModal" onClick={() => dispatch({type:'cameraModal'})}>
												<i className="fa fa-camera m-0" />{" "}
											</Link>
											
											<Link to={"#"} className="btn btn-primary ms-1" data-target="#postModal" onClick={() => dispatch({type:'postModal'})}>Post</Link>
											
										</div>
	
										<div className="profile-uoloaded-post border-bottom-1 pb-5">
											<img src={profile08} alt="" className="img-fluid w-100 rounded" />
											<Link className="post-title" to="/post-details">
												<h3 className="text-black">Collection of textile samples lay spread</h3>
											</Link>
											<p>
												A wonderful serenity has take possession of my entire soul like these sweet morning of spare which enjoy whole heart.A wonderful serenity has take 
												possession of my entire soul like these sweet morning of spare which enjoy whole heart.
											</p>
											<button className="btn btn-primary me-2">
												<span className="me-2"> <i className="fa fa-heart" /> </span>Like 
											</button>
											<button className="btn btn-secondary" onClick={() => dispatch({type:'replyModal'})}>
												<span className="me-2"> <i className="fa fa-reply" /></span>Reply
											</button>
										</div>
										<div className="profile-uoloaded-post border-bottom-1 pb-5">
											<img src={profile09} alt="" className="img-fluid w-100 rounded" />
											<Link className="post-title" to="/post-details">
												<h3 className="text-black">Collection of textile samples lay spread</h3>
											</Link>
											<p>
												A wonderful serenity has take possession of my
												entire soul like these sweet morning of spare which
												enjoy whole heart.A wonderful serenity has take
												possession of my entire soul like these sweet
												morning of spare which enjoy whole heart.
											</p>
											<button className="btn btn-primary me-2">
												<span className="me-2"> <i className="fa fa-heart" /> </span>Like
											</button>
											<button className="btn btn-secondary" onClick={() => dispatch({type:'replyModal'})}>
												<span className="me-2">  <i className="fa fa-reply" /></span>Reply
											</button>
										</div>
										<div className="profile-uoloaded-post pb-3">
											<img src={profile08} alt="" className="img-fluid  w-100 rounded" />
											<Link className="post-title" to="/post-details">
												<h3 className="text-black">Collection of textile samples lay spread</h3>
											</Link>
											<p>
												A wonderful serenity has take possession of my
												entire soul like these sweet morning of spare which
												enjoy whole heart.A wonderful serenity has take
												possession of my entire soul like these sweet
												morning of spare which enjoy whole heart.
											</p>
											<button className="btn btn-primary me-2">
												<span className="me-2"><i className="fa fa-heart" /></span>Like
											</button>
											<button className="btn btn-secondary" onClick={() => dispatch({type:'replyModal'})}>
												<span className="me-2"> <i className="fa fa-reply" /></span>Reply
											</button>
										</div>
										
									</div>
								</Tab.Pane> */}
								{/* <Tab.Pane id="about-me" eventKey='About'>
									<div className="profile-about-me">
										<div className="pt-4 border-bottom-1 pb-3">
											<h4 className="text-primary">About Me</h4>
											<p className="mb-2">
												A wonderful serenity has taken possession of my
												entire soul, like these sweet mornings of spring
												which I enjoy with my whole heart. I am alone, and
												feel the charm of existence was created for the
												bliss of souls like mine.I am so happy, my dear
												friend, so absorbed in the exquisite sense of mere
												tranquil existence, that I neglect my talents.
											</p>
											<p>
												A collection of textile samples lay spread out on
												the table - Samsa was a travelling salesman - and
												above it there hung a picture that he had recently
												cut out of an illustrated magazine and housed in a
												nice, gilded frame.
											</p>
										</div>
									</div>
									<div className="profile-skills mb-5">
										<h4 className="text-primary mb-2">Skills</h4>
										<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1"> Admin</Link>
										<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1" > Dashboard</Link>
										<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Photoshop</Link>
										<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Bootstrap</Link>
										<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Responsive</Link>
										<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Crypto</Link>
									</div>
									<div className="profile-lang  mb-5">
										<h4 className="text-primary mb-2">Language</h4>
										<Link to="/app-profile" className="text-muted pe-3 f-s-16">
											<i className="flag-icon flag-icon-us" />English
										</Link>
										<Link to="/app-profile" className="text-muted pe-3 f-s-16">
											<i className="flag-icon flag-icon-fr" />French
										</Link>
										<Link to="/app-profile" className="text-muted pe-3 f-s-16">
											<i className="flag-icon flag-icon-bd" />Bangla
										</Link>
									</div>
									<div className="profile-personal-info">
										<h4 className="text-primary mb-4 mt-4">
											Personal Information
										</h4>
										<div className="row mb-2">
											<div className="col-3">
												<h5 className="f-w-500"> Username <span className="pull-right">:</span></h5>
											</div>
											<div className="col-9">
												<span>{user.nickname || auth.user.given_name || auth.user.nickname}</span>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-3">
												<h5 className="f-w-500">Email<span className="pull-right">:</span></h5>
											</div>
											<div className="col-9">
												<span>{auth.user.email || ""}</span>
											</div>
										</div>
										{
											(country || city) ?
											(<div className="row mb-2">
												<div className="col-3">
													<h5 className="f-w-500">Location<span className="pull-right">:</span></h5>
												</div>
												<div className="col-9">
													<span>{auth.user.country || ""}, {auth.user.city || ""}</span>
												</div>
											</div>):<></>
										}
										{
											auth.user.whatsAppPhoneNumber ?
											(<div className="row mb-2">
												<div className="col-3">
													<h5 className="f-w-500"> whatsApp <span className="pull-right">:</span></h5>
												</div>
												<div className="col-9">
													<span>{auth.user.whatsAppPhoneNumber}</span>
												</div>
											</div>):<></>
										}
										<div className="row mb-2">
											<div className="col-3">
												<h5 className="f-w-500">  Role <span className="pull-right">:</span></h5>
											</div>
											<div className="col-9">
												<span>{auth.user.role}</span>
											</div>
										</div>
										{
											(auth.user.role && (auth.user.role !== "client" && auth.user.studyYear !== "Graduate")) &&
											<>
												<div className="row mb-2">
													<div className="col-3">
														<h5 className="f-w-500"> University <span className="pull-right">:</span></h5>
													</div>
													<div className="col-9">
														<span>{auth.user.university || ""}</span>
													</div>
												</div>
												<div className="row mb-2">
													<div className="col-3">
														<h5 className="f-w-500"> Faculty <span className="pull-right">:</span></h5>
													</div>
													<div className="col-9">
														<span>{auth.user.faculty || ""}</span>
													</div>
												</div>
												<div className="row mb-2">
													<div className="col-3">
														<h5 className="f-w-500"> Study Year <span className="pull-right">:</span></h5>
													</div>
													<div className="col-9">
														<span>{auth.user.studyYear || ""}</span>
													</div>
												</div>
											</>

										}
										{
											(auth.user.role && (auth.user.role === "client" || auth.user.studyYear === "Graduate")) &&
												<div className="row mb-2">
													<div className="col-3">
														<h5 className="f-w-500"> Company Name <span className="pull-right">:</span></h5>
													</div>
													<div className="col-9">
														<span>{auth.user.companyName}</span>
													</div>
												</div>
										}
										{
											(auth.user.role && auth.user.role !== "client" && auth.user.studyYear === "Graduate") &&
											<>
												<div className="row mb-2">
													<div className="col-3">
														<h5 className="f-w-500"> Study Year <span className="pull-right">:</span></h5>
													</div>
													<div className="col-9">
														<span>{auth.user.studyYear || ""}</span>
													</div>
												</div>
												<div className="row mb-2">
													<div className="col-3">
														<h5 className="f-w-500"> Experience Years <span className="pull-right">:</span></h5>
													</div>
													<div className="col-9">
														<span>{auth.user.experienceYears}</span>
													</div>
												</div>
												<div className="row mb-2">
													<div className="col-3">
														<h5 className="f-w-500"> Job State <span className="pull-right">:</span></h5>
													</div>
													<div className="col-9">
														<span>{auth.user.jobState}</span>
													</div>
												</div>
											</>
										}
										
										<div className="row mb-2">
											<div className="col-3">
												<h5 className="f-w-500">Year Experience<span className="pull-right">:</span></h5>
											</div>
											<div className="col-9">
												<span>07 Year Experiences</span>
											</div>
										</div> 
										<div className="profile-lang  mb-5 mt-3">
											<h4 className="text-primary mb-2">Interest Fields</h4>
											<div className="d-flex">
												{
													interestFields.map(field => (
														<p className="text-muted pe-3 f-s-16" key={field}>
															<i className="flag-icon flag-icon-us" />{field}
														</p>
													))
												}
											</div>
										</div>
									</div>
								</Tab.Pane> */}
								<Tab.Pane id="profile-settings" eventKey='About'>
									<div className="pt-3">
										<div className="settings-form">
											{/* <h4 className="text-primary">Edit Account</h4> */}
											<form onSubmit={handleSubmit(onSubmit)}>
												<div className="row">
													<div className="form-group mb-3 col-md-6">
														<label className="form-label" >Email</label>
														<input type="email" placeholder="Email" className="form-control" value={user.email} disabled/>
													</div>
													<div className="form-group mb-3 col-md-6">
														<label className="form-label">Username</label>
														<input type="text" placeholder="Nickname" className="form-control" value={user.username || user.nickname} disabled/>
													</div>
												</div>
												<div className="form-group mb-3">
													<label className="form-label">WhatsApp Phone Number</label>
													<input  {...register('whatsAppPhoneNumber')}  type="text" placeholder="010000000" className="form-control"/>
													{errors&& errors.whatsAppPhoneNumber && (<div className="text-danger fs-12"> {errors.whatsAppPhoneNumber.message} </div>)}
												</div>
												<div className="form-group mb-3">
													<label className="form-label">
													Interest Fields
													</label>
													<div className="form-control d-flex align-items-center mb-1" onClick={()=>setOpenInterestFields(!openInterestFields)}>
														{interestFields.length === 0 ? 'Choose...': interestFields.join(', ')}
													</div>
													{openInterestFields &&
													<div className="form-control h-100 p-4" ref={divRef}>
													{fieldsInterestList.map((val, index) => (
														<div key={index} className="form-check">
														<input
															type="checkbox"
															value={val}
															id={`checkbox-${index}`}
															className="form-check-input"
															checked={interestFields.includes(val)}
															onChange={handleFieldsChange}
														/>
														<label htmlFor={`checkbox-${index}`} className="form-check-label">
															{val}
														</label>
														</div>
													))}
													</div>
													}
													{errors&& errors.interestFields && (<div className="text-danger fs-12"> {errors.interestFields.message} </div>)}
													</div>
												{
													(auth.user.role !== "client")&&(
														<div className="row">
															<div className="form-group mb-3 col-md-6">
																<label className="form-label">Nickname</label>
																<input {...register('nickname')} type="text" placeholder="Nickname" className="form-control" />
																{errors&& errors.nickname && (<div className="text-danger fs-12"> {errors.nickname.message} </div>)} 

															</div>
															<div className="form-group mb-3 col-md-6">
																<label className="form-label">Github Handle</label>
																<input {...register('githubHandle')} type="text" placeholder="githubHandle" className="form-control" />
																{errors&& errors.githubHandle && (<div className="text-danger fs-12"> {errors.githubHandle.message} </div>)}

															</div>
														</div>
													)
												}

												<div className="row">
													<div className="form-group mb-3 col-md-6">
														<label className="form-label">Country</label>
														<input {...register('country')}  type="text" placeholder="1234 Main St" className="form-control" />
														{errors&& errors.country && (<div className="text-danger fs-12"> {errors.country.message} </div>)}

													</div>
													<div className="form-group mb-3 col-md-6">
														<label className="form-label">City</label>
														<input {...register('city')} type="text" placeholder="Apartment, studio, or floor" className="form-control"/>
														{errors&& errors.city && (<div className="text-danger fs-12"> {errors.city.message} </div>)}

													</div>
												</div>
												{
													(studyYear === "Graduate") &&
														<div className="form-group mb-3">
															<label className="form-label">Study Year</label>
															<select {...register('studyYear')}  onChange={handleStudyYear}
																className="form-select form-control solid mb-1"
																>
																<option value="" disabled>
																	Choose...
																</option>
																{studyYearList.map((val, index) => (
																	<option key={index}>{val}</option>
																))}
																</select>
																{errors && errors.studyYear && (<div className="text-danger fs-12">{errors.studyYear.message}</div>)} 

														</div>
												}
												{
													(auth.user.role !== "client" && studyYear !==  "Graduate")&&
													(
														<div className="row">
															<div className="form-group mb-3 col-md-4">
														<label className="form-label">Study Year</label>
														<select {...register('studyYear')}  onChange={handleStudyYear}
															className="form-select form-control solid mb-1"
															>
															<option value="" disabled>
																Choose...
															</option>
															{studyYearList.map((val, index) => (
																<option key={index}>{val}</option>
															))}
															</select>
															{errors && errors.studyYear && (<div className="text-danger fs-12">{errors.studyYear.message}</div>)} 

													</div>
													<div className="form-group mb-3 col-md-4">
														<label className="form-label">University</label>
														<input {...register('university')}  type="text" placeholder="University" className="form-control" />
														{errors&& errors.university && (<div className="text-danger fs-12"> {errors.university.message} </div>)}

													</div>
													<div className="form-group mb-3 col-md-4">
														<label className="form-label">Faculty</label>
														<input {...register('faculty')} type="text" placeholder="Faculty" className="form-control"/>
														{errors&& errors.faculty && (<div className="text-danger fs-12"> {errors.faculty.message} </div>)}

													</div>
													
												</div>
													)
												}
												{
													(auth.user.role === "client") && 
													<div className="form-group mb-3">
														<label className="form-label">company Name</label>
														<input  {...register('companyName')} type="text" placeholder="companyName" className="form-control"/>
														{errors&& errors.companyName && (<div className="text-danger fs-12"> {errors.companyName.message} </div>)}

													</div>
												}
												{
													(auth.user.role !== "client" && studyYear === "Graduate")&&
													
														<div className="row">
													<div className="form-group mb-3 col-md-4">
														<label className="form-label">Company Name</label>
														<input  {...register('companyName')} type="text" placeholder="companyName" className="form-control"/>
														{errors&& errors.companyName && (<div className="text-danger fs-12"> {errors.companyName.message} </div>)}
													</div>
													<div className="form-group mb-3 col-md-4">
														<label className="form-label">Experience Years</label>
														<input   {...register('experienceYears')} type="text" placeholder="Experience Years" className="form-control" />
														{errors&& errors.experienceYears && (<div className="text-danger fs-12"> {errors.experienceYears.message} </div>)}

													</div>
													<div className="form-group mb-3 col-md-4">
														<label className="form-label">Job State</label>
														<select
														{...register('jobState')} 
															onChange={handleJobState}
															className="form-select form-control solid mb-1"
														>
															<option value="" disabled>
															Choose...
															</option>
															{jobStateList.map((val, index) => (
															<option key={index}>{val}</option>
															))}
														</select>
														{errors&& errors.jobState && (<div className="text-danger fs-12"> {errors.jobState.message} </div>)}

													</div>
												</div>
													
												}
												

												<div className="form-group mb-1">
													<label className="form-label">Avatar</label>
													<input type="text" placeholder="https://codeQuest.com" className="form-control" value={avatar} onChange={(e) => {setAvatar(e.target.value); }}/>
												</div>		
												{/* {errors.avatar && (
													<div className="text-danger fs-12">
													{errors.avatar}
													</div>
												)} */}
																
												<button className="btn btn-primary mt-3" type="submit" disabled={clickLoading?true:false}>
												{
													clickLoading ?(
													<>
														Loading
														<span className="spinner-grow spinner-grow-sm" style={{marginLeft:"5px"}} role="status" aria-hidden={true}></span>
														<span className="sr-only">Loading...</span>  
													</>): <>Edit Account</>
													}
												</button>
											</form>
										</div>
									</div>
								</Tab.Pane>
							</Tab.Content>	
						</Tab.Container>		
					</div>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		  {/* send Modal */}
			  {/* <Modal className="modal fade" show={state.sendMessage} onHide={()=>dispatch({type:'sendMessage'})} centered>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Send Message</h5>
						<Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => dispatch({type:'sendMessage'})}>
							
						</Button>
					</div>
					<div className="modal-body">
						<form className="comment-form" onSubmit={(e) => { e.preventDefault(); dispatch({type:'sendMessage'}); }}>
							<div className="row">
								<div className="col-lg-6">
									<div className="form-group mb-3">
										<label htmlFor="author" className="text-black font-w600">  Name <span className="required">*</span> </label>
										<input type="text" className="form-control" defaultValue="Author" name="Author" placeholder="Author" />
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group mb-3">
										<label htmlFor="email" className="text-black font-w600"> Email <span className="required">*</span></label>
										<input type="text" className="form-control" defaultValue="Email" placeholder="Email" name="Email"/>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="form-group mb-3">
										<label htmlFor="comment" className="text-black font-w600">Comment</label>
										<textarea rows={4} className="form-control" name="comment" placeholder="Comment" defaultValue={""}/>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="form-group mb-3">
										<input type="submit" value="Post Comment" className="submit btn btn-primary" name="submit"/>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</Modal> */}
		  {/* Post Modal */}
			  {/* <Modal show={state.post} className="modal fade" id="postModal" onHide={() => dispatch({type:'postModal'})} centered>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Post</h5>
						<Button variant=""  type="button" className="close" data-dismiss="modal" onClick={() => dispatch({type:'postModal'})} >
							<span>×</span>
						</Button>
						
					</div>
					<div className="modal-body">
						<textarea name="textarea" id="textarea" cols={30} rows={5} className="form-control mb-2 bg-transparent" placeholder="Please type what you want...." defaultValue={""}/>
						<Link className="btn btn-primary btn-rounded mt-1" to="/app-profile">Post</Link>
					</div>
				</div>
			</Modal> */}
			 {/* Link Modal */}
			  {/* <Modal show={state.link}  className="modal fade post-input" id="linkModal" onHide={() => dispatch({type:'linkModal'})} centered>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Social Links</h5>
						<button type="button" className="btn-close" data-dismiss="modal" onClick={() => dispatch({type:'linkModal'})}>
						</button>
					</div>
					<div className="modal-body">
						<Link className="btn-social me-1 facebook" to="/app-profile"><i className="fab fa-facebook-f" /></Link>
						<Link className="btn-social me-1 google-plus" to="/app-profile"> <i className="fab fa-google-plus" /></Link>
						<Link className="btn-social me-1 linkedin" to="/app-profile"><i className="fab fa-linkedin" /></Link>
						<Link className="btn-social me-1 instagram" to="/app-profile"> <i className="fab fa-instagram" /></Link>
						<Link className="btn-social me-1 twitter" to="/app-profile"><i className="fab fa-twitter" /></Link>
						<Link className="btn-social me-1 youtube" to="/app-profile"><i className="fab fa-youtube" /></Link>
						<Link className="btn-social whatsapp" to="/app-profile"><i className="fab fa-whatsapp" /></Link>
					</div>
				</div>
			</Modal> */}
			 {/* Camera Modal */}
			  {/* <Modal show={state.camera}  className="modal fade" id="cameraModal" onHide={() => dispatch({type:'cameraModal'})} centered>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Upload images</h5>
						<button type="button" className="btn-close" data-dismiss="modal" onClick={() => dispatch({type:'cameraModal'})}>
						</button>
					</div>
					<div className="modal-body"> */}
						{/* <div className="input-group mb-3">
								<span className="input-group-text">Upload</span>
							<div className="form-file">
								<input type="file" className="form-file-input"/>
							</div>
						</div> */}
						{/* <div className="input-group custom_file_input mb-3">
							<span className="input-group-text">Upload</span>
							<div className="form-file">
								<input type="file" className="form-file-input form-control" />
							</div>
						</div>
					</div>
				</div>
			</Modal> */}
			 {/* Reply Modal */}
			  {/* <Modal   show={state.reply}  className="modal fade" id="replyModal" onHide={()=>dispatch({type:'replyModal'})} centered>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Post Reply</h5>
						<button type="button" className="btn-close"  onClick={() => dispatch({type:'replyModal'})}></button>
					</div>
					<div className="modal-body">
						<form>
							<textarea className="form-control" rows="4">Message</textarea>
						</form>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-danger light"  onClick={() => dispatch({type:'replyModal'})}>Close</button>
						<button type="button" className="btn btn-primary">Reply</button>
					</div>
				</div>
			</Modal> */}
			<ToastContainer/>
		</Fragment>
	  );
};

export default AppProfile;