import React from "react";
import "./index.css";
const Stepper = ({phase}) => {
  const steps = ["Register","Submit","Review","Announce"];
  const phases = ["registration" , "submission","review" , "ended"];
  const activeIndex = phases.findIndex((ele)=>ele === phase);
  return (
    <>
      <div className="stepper">
        {steps.map((step, index) => (
          <div className="step" key={index}>
            <div className="status">
              <div className="dot-container">
                <p className="text">{step}</p>
                <span className={`dot ${activeIndex >= index && "active"}`}>
                  {
                    activeIndex > index ||  activeIndex === 3 ? '✔':
                    activeIndex === index ? <svg xmlns="http://www.w3.org/2000/svg"width="6px"height="6px"viewBox="0 0 64 64"><circle cx="30" cy="30" r="30" fill="#eee" /></svg>
                    : `${index+1}`
                  }
                </span>
              </div>
              {index !== 3 && <span className={`line ${activeIndex > index && "active"}`}></span>}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Stepper;
