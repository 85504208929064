import React from "react";
//import { useAuthService } from "../../services/AuthService";
import { useAuth0 } from "@auth0/auth0-react";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const EDIT_USER_DATA_ACTION = "[edit user data action] edit user data";


// LEGACY: don't need a signup action for integrating with auth0
// export function signupAction(email, password, navigate) {
//   return async (dispatch) => {
//     const { signUp, saveTokenInLocalStorage, runLogoutTimer, formatError } =
//       useAuthService();
//     try {
//       await signUp(email, password);
//       const tokenDetails = JSON.parse(localStorage.getItem("userDetails"));
//       saveTokenInLocalStorage(tokenDetails);
//       runLogoutTimer(dispatch, tokenDetails.expiresIn * 1000, navigate);
//       dispatch(confirmedSignupAction(tokenDetails));
//       navigate("/quest-list");
//     } catch (error) {
//       const errorMessage = formatError(error);
//       dispatch(signupFailedAction(errorMessage));
//     }
//   };
// }

// LEGACY: login and logout are handled by the auth0 hook instead of these actions
// export function logoutAction(navigate) {
//   return (dispatch) => {
//     console.log("Success Logout");
//     navigate("/");
//     dispatch({ type: LOGOUT_ACTION });
//   };
// }

// export function loginAction(navigate) {
//   return async (dispatch) => {
//     const {
//       authentication,
//       checkAutoLogin,
//       formatError,
//     } = useAuthService();
//     try {
//       checkAutoLogin(dispatch, navigate);
//       navigate("/quest-list");
//     } catch (error) {
//       const errorMessage = formatError(error);
//       dispatch(loginFailedAction(errorMessage));
//     }
//   };
// }

// export function Logout(navigate) {
//   // localStorage.removeItem("userDetails");
//   // navigate to home
//   //history.push('/login');

//   return {
//     type: LOGOUT_ACTION,
//   };
// }

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function editUserDataAction(payload) {
  return {
    type: EDIT_USER_DATA_ACTION,
    payload,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
