import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { useSearchParams ,useNavigate} from "react-router-dom";
import Pagination from "./components/Pagination";
import Arrow from "./components/Arrow";
import searchTable, { sortTable } from "./utils";
import useRegistrationsAPI from "../../../hooks/useGetRegistrationsAPI"; // Adjust the import path if needed
import { useSelector } from 'react-redux';

const RegistrationLists = () => {
  const { auth } = useSelector((state) => state.auth);
  const [registrations, setRegistrations] = useState([]);
  const navigate = useNavigate();   
  const {id} = useParams();
  const { isLoading, data } = useRegistrationsAPI(id);
  const [fullData, setFullData] = useState([]);
  const [sortedBy, setSortedBy] = useState("");
  const [sortStatus, setSortStatus] = useState("");
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get("search");
  const isAdmin =
    auth.user.role === "admin";

  if(!isAdmin){
    navigate(`/quest-view/${id}`)
  }

  useEffect(() => {
    if (!isLoading) {
      setFullData(data);
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (searchValue) {
      const result = searchTable(searchValue, data);
      setFullData(result);
    } else {
      setFullData(data);
    }
  }, [searchValue, data]);

  const handleSortTable = (column) => {
    if (sortedBy !== column || sortStatus === "") {
      setSortStatus("asc");
      setSortedBy(column);
      setFullData(sortTable(fullData, "asc", column));
    } else if (sortStatus === "asc") {
      setSortStatus("desc");
      setFullData(sortTable(fullData, "desc", column));
    } else {
      setSortStatus("");
      setFullData(data);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">Registrations</h4>
      </div>
      <div className="row">
        <div className="col-xl-13">
          <div className="table-responsive">
            <table className="registration-table table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
              <thead>
                <tr style={{ textAlign: "center" }}>
                  <th>No</th>
                  <th>User ID</th>
                  <th>Quest Title</th>
                  <th>Status</th>
                  <th>Role</th>
                  <th
                    className="sortBy"
                    onClick={() => handleSortTable("joinedOn")}
                  >
                    Registration Date
                    {sortedBy === "joinedOn" && (
                      <Arrow direction={sortStatus} />
                    )}
                  </th>
                </tr>
              </thead>
              <tbody style={{ textAlign: "center" }}>
                {registrations.map((registration, index) => (
                  <tr key={index}>
                    <td>#{registration.id}</td>
                    <td>{registration.userId}</td>
                    <td>{registration.quest.title}</td>
                    <td>
                      <span
                        className={`badge badge-lg light badge-${
                          registration.status === "pending"
                            ? "warning"
                            : "success"
                        }`}
                      >
                        {registration.status}
                      </span>
                    </td>
                    <td>{registration.role}</td>
                    <td>
                      {new Date(registration.joinedOn).toLocaleString("en-US", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </td>{" "}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* Start Pagination */}
        <Pagination
          data={fullData}
          setQuests={setRegistrations}
          sortStatus={sortStatus}
        />
      </div>
    </>
  );
};

export default RegistrationLists;
