import { lazy, Suspense, useEffect } from "react";
// Components
import Index from "./jsx/index";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
// Actions
import { connect, useDispatch, useSelector } from "react-redux";
// Style
import "../node_modules/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import ProfileData from "./jsx/pages/ProfileData";
import { loginConfirmedAction } from "./store/actions/AuthActions";
import { auth, users } from "./services/api/models";

function App() {
  const { auth } = useSelector((state) => state.auth);
  const { isAuthenticated, isLoading, loginWithRedirect, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  useEffect(() => {
    // if user is auth0 authenticated, but not saved in our state
    const storeUserData = async () => {
      if (isAuthenticated && Object.keys(auth.user).length === 0) {
        const accessToken = await getAccessTokenSilently();
        
        // get database user
        const headers = { Authorization: `Bearer ${accessToken}` }
        const options = { headers: headers }
        const dbUser = await users.usersGet(1, 0, {auth0Id: user.sub}, options);
        // merge the two users to get all data
        if (dbUser.data.data.length !== 0) {
          const mergedUser = {
            ...user,
            db_id: dbUser.data.data[0].id || null,
            nickname: dbUser.data.data[0].nickname,
            country: dbUser.data.data[0].country,
            city: dbUser.data.data[0].city,
            whatsAppPhoneNumber:dbUser.data.data[0].whatsAppPhoneNumber,
            university:dbUser.data.data[0].university || "",
            faculty :dbUser.data.data[0].faculty || "", 
            studyYear:dbUser.data.data[0].studyYear || "",
            interestFields: dbUser.data.data[0].interestFields,
            companyName : dbUser.data.data[0].companyName || "",
            jobState:dbUser.data.data[0].jobState || "",
            experienceYears: dbUser.data.data[0].experienceYears || "",
            githubHandle :dbUser.data.data[0].githubHandle || "",
            avatar: dbUser.data.data[0].avatar || "",
          }
          dispatch(loginConfirmedAction({ user: mergedUser, accessToken }));
        }
        // else{
        //   dispatch(loginConfirmedAction({ user, accessToken }));
        // }      
      }
    };
    storeUserData();
  }, [isAuthenticated, auth.user, user, getAccessTokenSilently, getIdTokenClaims, dispatch]);

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  // user is authenticated by auth0, but hasn't chosen a role yet
  if (isAuthenticated && user['role'] === undefined) {
    return (
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <ProfileData />
      </Suspense>
    );
  }
  else if (isAuthenticated) {
    return (
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <Index />
      </Suspense>
    );
  }

  return null;
}

export default App;
