import React from "react";

const Technologies = ({ tech }) => {
  return (
    <div className="d-flex flex-wrap gap-1 align-items-center justify-content-center" style={{ width: "170px" ,color:'#6a6a6a',opacity:'0.8'}}>
      { tech?.map((ele, i) => (
        <span
          className="p-1 rounded fs-12"
          key={i}
          style={{ backgroundColor: "#eee" }}
        >
          {ele}
        </span>
      ))}
    </div>
  );
};

export default Technologies;
