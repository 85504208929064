import React from "react";

const Arrow = ({ direction }) => {
  return (
    <>
      {
        direction === "desc" ? <i className="fa fa-arrow-down" /> : 
        direction === "asc" ? <i className="fa fa-arrow-up" /> : 
        null
      }
    </>
  );
};

export default Arrow;
