import { useState, useCallback, useEffect } from "react";
import { feedbacks, quests } from "../services/api/models";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";

/**
 * Custom hook for managing quest submission.
 *
 * This hook provides functionality for registering a contestant for a quest, including handling API requests,
 * managing loading states, and tracking errors.
 *
 * @param {string} questId - The ID of the quest for which the submission is to be processed.
 *
 * @returns {{
 *   isLoading: boolean,              // A boolean indicating if the submission process is ongoing.
 *   data: object | null,             // The data returned from the API upon successful submission, or null if no data is available.
 *   error: object | null,            // An error object if an error occurred during the submission process, or null if no error is present.
 *   questSubmissionRequest: function,// A function that initiates the quest submission process. It accepts submission data and returns a promise.
 * }}
 */
const useQuestSubmissionAPI = (questId) => {
  const { auth } = useSelector((state) => state.auth);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  // Check if user is registered and approved once the hook is called
  useEffect(() => {
    const checkUserRegistration = async () => {
      try {
        // Get user token
        const accessToken = await getAccessTokenSilently();

        // Request to get participants of the quest
        const response = await quests.questsQuestIdEnlistGet(
          questId,
          5000, // Number of participants
          0, // Participants to skip
          { isApproved: true }, // Filters
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
      } catch (error) {
        console.error("Error checking user registration:", error);
        setError(error);
      }
    };

    if (questId) {
      checkUserRegistration();
    }
  }, [getAccessTokenSilently, questId]);

  /**
   *
   */
  const questSubmissionRequest = useCallback(
    async (submissionData) => {
      setIsLoading(true);
      setError(null);

      try {
        // Check for required parameters
        if (!questId || !submissionData) {
          throw new Error("Quest submission data is missing");
        }

        // clients can't submit
        if (auth.user.role === "client") {
          throw new Error("Clients can't submit for quests");
        }

        // Prepare authorization parameters
        const accessToken = await getAccessTokenSilently();
        const options = { headers: { Authorization: `Bearer ${accessToken}` } };

        // Prepare submission data
        // ! Commented properties are marked as additional by the backend
        const submissionRequestData = {
          questId: Number.parseInt(questId),
          totalScore: 0,
          // submittedOn: new Date(),
          // status: "pending",
          note: submissionData.submissionNote,
          fileUrl: submissionData.projectFilesUrl,
          videoUrl: submissionData.videoWalkthroughUrl,
        };

        // Submit user files
        const submissionResponse = await quests.questsQuestIdSubmissionsPost(
          questId,
          submissionRequestData,
          options
        );

        // Prepare feedback data
        // ! Commented properties are marked as additional by the backend
        const feedbackRequestData = {
          // createdOn: new Date(),
          dataRating: Number.parseInt(submissionData.rateData),
          dataReason: submissionData.rateDataReason || "",
          prizeRating: Number.parseInt(submissionData.ratePrize),
          prizeReason: submissionData.ratePrizeReason || "",
          interactionRating: Number.parseInt(submissionData.rateInteraction),
          interactionReason: submissionData.rateInteractionReason || "",
          additionalComments: submissionData.additionalComments || "",
          submissionId: submissionResponse.data.id,
          userId: submissionResponse.data.user.id,
        };

        // Submit user feedback
        const feedbackResponse = await feedbacks.feedbackPost(
          feedbackRequestData,
          options
        );

        if (!feedbackResponse || !submissionResponse) {
          throw new Error("Quest submission request failed");
        }

        setData(submissionResponse);
      } catch (error) {
        setError(error);
        console.error("Quest submission request error:", error);

        if (error?.response?.data?.data?.userId?.message) {
          console.log(error?.response?.data?.data?.userId?.message);
          
          setError(error.response.data.data.userId.message);
          throw new Error(error.response.data.data.userId.message);
        }
        else if (
          error.message == "Request failed with status code 400"
        )
        {
          setError(error);
          throw new Error("User didn't register for this quest");
        }
        else {
          setError(error);
          throw new Error(error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [getAccessTokenSilently, questId]
  );

  return { isLoading, data, error, questSubmissionRequest };
};

export default useQuestSubmissionAPI;
