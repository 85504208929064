export const totalPrize = (Prizes) => {
  
  if(Prizes && Prizes.length){
    return Prizes.reduce(
      (accumulator, Prize) => accumulator + Prize.value,
      0
    );
  }else{
    return []
  }
 
};
// Calc Number of days to display in table
export const numberOfDays = (quest) => {
  let days = 0 , end = 0;
  const {phase , registrationDeadline , reviewDeadline , submissionDeadline , endedOn} = quest;
  switch(phase){
    case "registration":
      end = registrationDeadline;
      break
    case "submission":
      end = submissionDeadline;
      break
    case "review":
      end = reviewDeadline;
      break
    case "ended":
      end = endedOn;
      break
    default:
      end = new Date();
      break
  }
  let start = new Date();
  end = new Date(end);
  let timeDifference = end - start;
  days = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));  //(timeDifference / oneDay)
  console.log({id:quest.id , phase , start ,end , days , state:days<0?"This old date":"Valid date" })
  if(days < 0) return 0;
  return days;
};

// create formate to date ex(Tuesday, March 5th)
export const dateFormated = (value) => {
  const date = new Date(value);
  const options = { weekday: "long", month: "long", day: "numeric" };
  return `${date.toLocaleDateString("en-US", options)}th`;
};

// Sort operation when click on (prizes - days)
export const sortTable = (data, order, sortBy) => {
  let result = [...data];

  console.log("Data:", data);
  console.log("Sort order:", order); // asc : Ascending | des : Descending
  console.log("Sort by:", sortBy);

  switch (sortBy) {
    case "prize":
      result.sort((a, b) => {
        if (order === "asc") {

          console.log(`a (id:${a.id}) - b (id:${b.id}) = `, totalPrize(a.prizes) - totalPrize(b.prizes));
          return totalPrize(a.prizes) - totalPrize(b.prizes);
        } else {
          
          console.log(`b (id:${b.id}) - a (id:${a.id}) = ` , totalPrize(b.prizes) - totalPrize(a.prizes));
          return totalPrize(b.prizes) - totalPrize(a.prizes);
        }
      });
      break;

    case "days":
      result.sort((a, b) => {
        if (order === "asc") {
          return (
            numberOfDays(a.registrationDeadline, a.submissionDeadline) -
            numberOfDays(b.registrationDeadline, b.submissionDeadline)
          );
        } else {
          return (
            numberOfDays(b.registrationDeadline, b.submissionDeadline) -
            numberOfDays(a.registrationDeadline, a.submissionDeadline)
          );
        }
      });
      break;
    case "submittedOn":
      result.sort((a, b) => {
        const dateA = new Date(a.submittedOn);
        const dateB = new Date(b.submittedOn);
    
        if (order === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
        break;
      case "joinedOn":
          result.sort((a, b) => {
            const dateA = new Date(a.joinedOn);
            const dateB = new Date(b.joinedOn);
        
            if (order === "asc") {
              return dateA - dateB;
            } else {
              return dateB - dateA;
            }
          });
            break;
    default:
      result = [...data];
      console.log("Return original data");
      break;
  }
  console.log("Sorted data:", result);
  return result;
};

// Search on Table by (Quest Title , Type , Tech)
export default function searchTable(value, data) {
  console.log("Searching value:", value);

  const result = data.filter((ele) => {
    const findQuests = isInclude(ele.title, value);
    const findType = isInclude(ele.category.name, value);
    let findTechnologies = []
    if(ele.technologies){
      findTechnologies = ele.technologies.some((tech) =>
        isInclude(tech, value)
      );
    }

    console.log(`Row Result (id: ${ele.id}):`, {
      title: ele.title,
      categoryName: ele.category.name,
      findQuests: findQuests,
      findType: findType,
      findTechnologies: findTechnologies,
    });

    return findQuests || findType || findTechnologies;
  });

  console.log("Search result : ", result);

  return result;
}

const isInclude = (data, value) => data.toLowerCase().includes(value.toLowerCase());

export const FilterTable = (fields, data) => {
  const { id, title, type, technologies } = fields;
  console.log(id);
  const result = data.filter((row) => {
    // Check if any of the fields match
    return (
      (id === undefined || row.id.includes(id)) &&
      (title === undefined || row.title.includes(title)) &&
      (type === undefined || row.category.name.includes(type)) &&
      (technologies === undefined ||
        technologies.every((tech) => row.technologies.includes(tech)))
    );
  });
  return result;
};


export const applyRolesOnMenu = (menu, userType) => {
  if (userType === 'client') {
      // Iterate over each menu item
      return menu.map(item => {
          // Filter 'My Submissions' from the content array if present
          const filteredContent = item.content.filter(subItem => subItem.to !== 'my-submissions');
          
          // Return the item with updated content
          return {
              ...item,
              content: filteredContent
          };
      });
  }
  return menu;
}

export const findPayeed = (prizes)=>{
  const hasPayee = prizes.some(prize => prize.hasOwnProperty('payee'));
  return hasPayee; 

}